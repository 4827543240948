import React from "react";
import NavBar from "../components/SharedComponents/nav/NavBar";
import {
  DLoadSubContainer,
  DownloadContentContainer,
  DownloadContainer,
  DownloadImage,
  DownloadSubText,
  DownloadDocLink,
} from "../components/Download/styles/Download.styled";
import Footer from "../components/SharedComponents/Footer/Footer";
import GradientHeading from "../components/SharedComponents/GradientHeading/GradientHeading";
import Button from "../components/SharedComponents/Button";

const Download = () => {
  // Function to determine download path based on user agent

  const download = async () => {
    // Open the documentation link in a new tab
    window.open(
      "https://avai.gitbook.io/spingle-knowledge-base/using-the-premiere-pro-extension",
      "_blank",
    );

    let downloadPath = "";
    let downloadType = ""; // To identify the type of download

    // Function to handle initiating the download after GA4 event is sent
    const initiateDownload = () => {
      window.location.href = downloadPath;
    };

    // Check if navigator.userAgentData is available
    if (navigator.userAgentData) {
      try {
        // Request high-entropy values including architecture
        const ua = await navigator.userAgentData.getHighEntropyValues([
          "architecture",
          "platform",
        ]);
        const arch = ua.architecture.toLowerCase(); // e.g., "arm64", "x86"
        const platform = ua.platform.toLowerCase(); // e.g., "macos"

        console.log("User Agent Data Architecture:", arch);
        console.log("User Agent Data Platform:", platform);

        if (platform.includes("mac")) {
          if (arch.includes("arm")) {
            // ARM (Apple Silicon)
            downloadPath =
              "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.2.1/Spingle_AI_Mac_Installer_arm.dmg";
            downloadType = "Mac ARM";
          } else if (arch.includes("x86")) {
            // Intel
            downloadPath =
              "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.2.1/Spingle_AI_Mac_Installer_x86.dmg";
            downloadType = "Mac Intel";
          } else {
            alert("Unable to determine Mac architecture.");
            return;
          }
        } else if (platform.includes("windows")) {
          // Windows
          downloadPath =
            "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.2.1/Spingle_AI_Windows_Installer.exe";
          downloadType = "Windows";
        } else {
          // Unsupported platform
          alert(
            "This app is only available for Mac or Windows desktop/laptop devices.",
          );
          return;
        }
      } catch (error) {
        console.error("Error accessing userAgentData:", error);
        alert("Unable to determine system architecture.");
        return;
      }
    } else {
      // Fallback to traditional userAgent and navigator.platform
      const userAgent = navigator.userAgent.toLowerCase();
      const platform = navigator.platform.toLowerCase();
      const isMac = userAgent.includes("macintosh") || platform.includes("mac");
      const isAppleSilicon =
        isMac &&
        (userAgent.includes("apple m1") ||
          userAgent.includes("apple silicon") ||
          userAgent.includes("arm64"));
      const isIntelMac =
        isMac && (userAgent.includes("intel") || platform.includes("intel"));

      console.log("User Agent:", userAgent);
      console.log("Platform:", platform);
      console.log("isAppleSilicon:", isAppleSilicon);
      console.log("isIntelMac:", isIntelMac);

      if (isMac) {
        if (isAppleSilicon) {
          // ARM (Apple Silicon)
          downloadPath =
            "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.2.1/Spingle_AI_Mac_Installer_arm.dmg";
          downloadType = "Mac ARM";
        } else if (isIntelMac) {
          // Intel
          downloadPath =
            "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.2.1/Spingle_AI_Mac_Installer_x86.dmg";
          downloadType = "Mac Intel";
        } else {
          alert("Unable to determine Mac architecture.");
          return;
        }
      } else if (userAgent.includes("win")) {
        // Windows
        downloadPath =
          "https://github.com/AudioVideoAI/SpingleReleases/releases/download/v1.2.1/Spingle_AI_Windows_Installer.exe";
        downloadType = "Windows";
      } else {
        // Unsupported platform
        alert(
          "This app is only available for Mac or Windows desktop/laptop devices.",
        );
        return;
      }
    }

    console.log("Download Path:", downloadPath);
    console.log("Download Type:", downloadType);
    // Function to send GA4 event

    const sendGA4Event = () => {
      if (typeof window.gtag === "function") {
        console.log("Sending GA4 Event...");
        window.gtag("event", "download_click", {
          download_type: downloadType,
          download_path: downloadPath,
          event_callback: () => {
            console.log("Event callback triggered, initiating download.");
            initiateDownload();
          },
        });

        // Fallback in case the event_callback doesn't fire within 1 second
        setTimeout(() => {
          console.log("Event callback fallback triggered.");
          initiateDownload();
        }, 1000);
      } else {
        console.warn(
          "gtag function not found. Initiating download immediately.",
        );
        initiateDownload();
      }
    };

    // Send the GA4 event
    sendGA4Event();
  };
  return (
    <div>
      <NavBar showLink={false} />
      <DownloadContainer>
        <DownloadContentContainer>
          <DLoadSubContainer>
            <GradientHeading
              mb={0.5}
              width={"70%"}
              text={"Download Spingle to Get Started"}
            />
            <DownloadSubText>
              We're excited to partner with you on your creative journey,
              helping you turn your footage into compelling stories. We want to
              help you spend more time creating the stories that matter.
            </DownloadSubText>
            <Button
              text={"Download Now"}
              colour={"#0F0F0F"}
              padding={"1rem 2rem"}
              onClick={download}
              hover={"#721ED8"}
              hoverText={"white"}
            />
            <DownloadDocLink
              href="https://avai.gitbook.io/spingle-knowledge-base/using-the-premiere-pro-extension"
              target="_blank"
            >
              > Spingle Docs{" "}
            </DownloadDocLink>
          </DLoadSubContainer>
          <DLoadSubContainer>
            <DownloadImage src="https://res.cloudinary.com/dd0g0joex/image/upload/v1726364186/aesthetic-retro-8bit-landscape-35bdqga3u3xw4hu7_buimgt.webp" />
          </DLoadSubContainer>
        </DownloadContentContainer>
      </DownloadContainer>
      <Footer />
    </div>
  );
};

export default Download;
